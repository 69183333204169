@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=REM&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=REM:wght@400;600&display=swap');
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #1e2232;
    font-family: 'Montserrat', sans-serif;
    font-family: 'REM', sans-serif;

}

.main{
    padding: 4rem;
    padding-top: 7rem;

}
.left{
    grid-area: left;
}

.right{
    grid-area: right;
}

.center{
    grid-area: center;
}

.main{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "left center right";

}

@media screen and (max-width: 768px)
{
    .main{
        grid-template-columns: 2fr;
        grid-template-areas:
        "left center"
        "right right";
        padding-top: 6rem;
        /* padding left right  */
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .heading{
        font-size: 19px !important;
    }

    .subheading{
        font-size: 10px !important;
    }
    .myform-div{
      /* margin-left: 0px !important;
      margin-right: 0px !important; */
      padding: 9px !important;
      width: 100% !important;

    }
    .right{
      padding: 0% !important;
      margin: 0% !important;
    }
   /* stop typing */
    /* .heading-typed{ 
      display: none !important;
     } */

     
    /* image size small  */
    .center img{
      width: 150px !important;
      height: 150px !important;

    }

}

.heading{
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 1rem;
}

.heading-typed{
    color: #00d09c;
}
.typed-cursor {
  color: #00d09c;
}

.left, .center, .right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.subheading{
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 1rem;
}

.myform-div {
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 400px;
    width: 350px;
    margin: 0 auto;
    margin-top: 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    


  }
  
  .myform {
    width: 100%;
    margin: 0 auto;
  }
  h5{
    font-size: 10px;
    font-weight: 400;
  }
  .input {
    width: 100%;
    padding: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    box-shadow: none;
    margin-bottom: 10px;
  }
  
  
  
  .btn {
    width: 100%;
    background-color: #00d09c;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    box-shadow: none;
  
  }
  
  .btn:hover {
    transition: ease-in-out 0.3s;
    background-color: #8587f0;
  }
  
  .input::-webkit-input-placeholder {
    color: #ccc;
  }
  
  .input::-moz-placeholder {
    color: #ccc;
  }
  
  .input:-ms-input-placeholder {
    color: #ccc;
  }
  
  .input:-webkit-focus-ring {
    color: transparent;
    outline: none;
  }
  
.form-head
{
    font-size: 1.4rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #00d09c;
    font-family: 'REM', sans-serif;
    font-weight: 600;

}


