
:root{
    --color-1:#f68121;
    --color-2:#ed1c24;
    --color-3:#582c8b;
    --color-4:#0166b4;
}
.page-wrapper{
    padding: 100px 0;
}
.infographic-cards {
    width: 100%;
    position: relative;
    display: flex;
    gap: 12%;
    justify-content: center;
    padding-left: 0;

}
.infographic-cards li {
    list-style: none;
    width: calc(100% /4);
    height: 230px;
    padding: 25px 30px;
    background: #eee;
    position: relative;
    background-image: linear-gradient(to bottom right, #e9eaecda, #ffffff);
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.25));
    box-shadow: inset 2px 2px 2px white, inset -1px -1px 1px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: inline-block;
    margin-bottom: 25px;
    border-radius: 20px;
}
.infographic-cards li h5{
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 15px;
  }
 .infographic-cards li h6{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
  }
 .infographic-cards li.color-1{
    border: 10px solid var(--color-1);
  }
  
 .infographic-cards li.color-2{
    border: 10px solid var(--color-2);
  }
  
 .infographic-cards li.color-3{
    border: 10px solid var(--color-3);
  }
  
 .infographic-cards li.color-4{
    border: 10px solid var(--color-4);
  }
  

  .infographic-cards li i{
    font-size: 35px;
    margin-bottom: 15px;
  }
  .infographic-cards li.color-1 i{
    color: var(--color-1);
  }
  
 .infographic-cards li.color-2 i{
    color: var(--color-2);
  }
  
 .infographic-cards li.color-3 i{
    color: var(--color-3);
  }
  
 .infographic-cards li.color-4 i{
    color: var(--color-4);
  }
 
  .number-box{
    position: absolute;
      bottom: 0;
      padding: 15px;
      font-size: 23px;
      border-radius: 60px 60px 0 0;
      width: 60px;
      height: 50px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: inset 0.25em 0.25em 0.25em rgba(0, 0, 0, 0.2), 0em 0.05em rgba(255, 255, 255, 0);
      font-weight: 800;
      color: #fff;
  }
 .infographic-cards li.color-1 .number-box{
    background: var(--color-1);
  }
  
 .infographic-cards li.color-2 .number-box{
    background: var(--color-2);
  }
  
 .infographic-cards li.color-3 .number-box{
    background: var(--color-3);
  }
  
 .infographic-cards li.color-4 .number-box{
    background: var(--color-4);
  }
  
@media screen and (max-width:991px){
    .infographic-cards{
        flex-wrap: wrap;
    }
    .infographic-cards li {
        flex: 0 0 auto;
        width: calc(100% /2.5);
    }
}
@media screen and (max-width:578px){
    
    .infographic-cards li {
        width: calc(100% /1);
    }
}