body {
	align-items: center;
	justify-content: center;
}

$animationSpeed: 20s;

@keyframes scroll {
	0% { transform: translateX(0); }
	// 100% { transform: translateX(calc(-250px * 7))}
	100% { transform: translateX(calc(-80px * 7))}
}

.slider {
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: auto;
	
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 8);
	}
	.slide {
		height: 100px;
		width: 250px;
		margin-right: 20px;
	}
	img {
        height: 100px; /* Set the default height */
        width: auto;   /* Automatically adjust the width */
      }
	  
}