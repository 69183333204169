@media screen and (max-width: 768px){
   .heading-typed {
      font-size: 1rem;
   }
}
.loginbtn{
   background-color: #ad8646 !important;
}
.mainnav{
   z-index: 999;
}