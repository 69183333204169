.main-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 20px;
    grid-template-areas:
    "left right";
}

@media screen and (max-width: 768px){
    .main-container{
        grid-template-columns: 1fr;
        grid-template-areas:
        "right"
        "left";
    }
    Box{
        width: 300;
    }
    
}

.left{
    grid-area: left;
    align-items: start;
    padding-left: 20px;
}
.right{
    grid-area: right;
}
.myinp{
    width: 30%;
    padding: 5px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(83,0,244,.1);
    
}

.userinp1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.mon-inv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}



.rightdata{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}