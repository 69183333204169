.main{
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 100vh;
    width: 100vw; 
    /* background-color: #f1f1f1; */
}


.main-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    grid-template-areas: 
    "left right"
    ;
    width: 100vw;
}

.left{
    grid-area: left;
    /* move up  */

}

.right{
    grid-area: right;
    
}


.plan{
    /* cover  */
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    background-size: cover;
    height: 90%;    
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}